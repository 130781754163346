<template>
    <div class="BigBox">
        <div class="Head">
            <div class="Btn">
                <img @click="GoHome" src="../../assets/CulturalHomePage/PomePage.png" alt="">
            </div>
            <div>倾斜摄影</div>
            <div class="Btn">
                <img @click="GoLogin" src="../../assets/CulturalHomePage/personal.png" alt="">
            </div>
        </div>
        <div class="ConBox">

            <QXSY v-if="qxsyData.length>0" :list="qxsyData"></QXSY>
        </div>

    </div>
</template>
<script>
import QXSY from '../Scenicspace/QXSY.vue'
export default {
    components: { QXSY },
    data () {
        return {
            id: '1681154335096127490',
            qxsyData: []
        }
    },
    mounted () {
        this.getQxsyData()
    },
    methods: {
        // 获取倾斜摄影数据
        getQxsyData () {
            var axios = require('axios')
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/obliquePhoto/list/' + this.id,
            }
            axios(config)
                .then((res) => {
                    this.qxsyData = res.data
                })
                .catch(function (error) { })
        },
        // 返回个人中心
        GoLogin () {
            if (this.$cookies.get("token") == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        // 返回首页
        GoHome () {
            this.$router.push('/');
        },
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/unify.less';

.BigBox {
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/Scenicspace/new/QiTaBg.jpg') no-repeat;
    background-size: 100% 100%;
    .Head {
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10%;
        box-sizing: border-box;
        // margin: 0 auto;
        font-size: @First_Title_Size;
        background: #193447;
        color: #fff;
        .Btn {
            height: 7vh;
            width: 7.5vh;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .ConBox {
        padding: 2vh 0;
        width: 80%;
        margin: 0 auto;
    }
}
</style>
