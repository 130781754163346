<template>
    <!-- 倾斜摄影 -->
    <div class="ConBox" v-for="(item,index) in list" :key="index">
        <div class="imgBox">
            <a class="card" href="#" target="_blank" ref="card" @mousemove="move($event,index)" @mouseleave="leave(index)" @mouseover="over(index)">
                <div class="reflection" ref="refl"></div>
                <img :src="item.logoUrl" />
            </a>
        </div>
        <div class="desBox">
            <p>{{ item.title }}</p>
            <p>内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    props: {
        list: {
            type: Array,
            default: [],
        }
    },
    data () {
        return {

        }
    },
    mounted () {
        this.init()
    },
    methods: {
        init () {
            $('.ConBox:even').css('flex-direction', 'row-reverse')
        },
        over (idx) {
            const refl = $('.reflection')[idx]
            refl.style.opacity = 1;
        },
        leave (idx) {
            const card = $('.card')[idx]
            const refl = $('.reflection')[idx]
            // const card = this.$refs.card;
            // const refl = this.$refs.refl;
            card.style.transform = `perspective(500px) scale(1)`;
            refl.style.opacity = 0;
        },

        move (event, idx) {
            const card = $('.card')[idx]
            const refl = $('.reflection')[idx]
            const relX = (event.offsetX + 1) / card.offsetWidth;
            const relY = (event.offsetY + 1) / card.offsetHeight;
            const rotY = `rotateY(${(relX - 0.5) * 60}deg)`;
            const rotX = `rotateX(${(relY - 0.5) * -60}deg)`;
            card.style.transform = `perspective(500px) scale(1.5) ${rotY} ${rotX}`;
            const lightX = this.scale(relX, 0, 1, 150, -50);
            const lightY = this.scale(relY, 0, 1, 30, -100);
            const lightConstrain = Math.min(Math.max(relY, 0.3), 0.7);
            const lightOpacity = this.scale(lightConstrain, 0.3, 1, 1, 0) * 255;
            const lightShade = `rgba(${lightOpacity}, ${lightOpacity}, ${lightOpacity}, 1)`;
            const lightShadeBlack = `rgba(0, 0, 0, 1)`;
            refl.style.backgroundImage = `radial-gradient(circle at ${lightX}% ${lightY}%, ${lightShade} 20%, ${lightShadeBlack})`;
        },
        scale: (val, inMin, inMax, outMin, outMax) =>
            outMin + (val - inMin) * (outMax - outMin) / (inMax - inMin)
    }


}
</script>
<style lang="less" scoped>
@import '../../assets/unify.less';

.ConBox {
    height: 36vh;
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: nowrap;
    .imgBox {
        width: 60%;
        height: 100%;
        .card {
            display: block;
            background-color: #ccc;
            width: 100%;
            height: 100%;
            transition: all 0.1s ease;
            border-radius: 3px;
            position: relative;
            z-index: 1;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0);
            overflow: hidden;
            cursor: pointer;
            .reflection {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                left: 0;
                top: 0;
                transition: all 0.1s ease;
                opacity: 0;
                mix-blend-mode: soft-light;
            }
            img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                -webkit-filter: grayscale(0.65);
                filter: grayscale(0.65);
                transition: all 0.3s ease;
            }
        }
        .card:hover {
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
            z-index: 2;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
            img {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
        }
    }
    .desBox {
        width: 40%;
        height: 100%;
        padding: 2rem;
        box-sizing: border-box;
        background-color: #fff;
        p:nth-child(1) {
            font-size: @First_Title_Size;
            text-align: center;
        }
        p:nth-child(2) {
            font-size: @Content_Text_Size;
        }
    }
}
</style>